export function extractDataFromObj<T>(obj: any, path: string[]) {
  if (!path || !obj) return null;

  let results: any = { ...obj };
  for (const key of path) {
    if (key in results) {
      results = results[key];
    } else {
      return null;
    }
  }
  if (results) return results as T;

  return null;
}

export function constructObjectRecursively(path: string[], value: any): object {
  if (path.length === 0) return value;
  const [key, ...rest] = path;
  return { [key]: constructObjectRecursively(rest, value) };
}